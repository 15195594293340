/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #2e2e2e;
  --ion-color-dark-rgb: 46, 46, 46;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #282828;
  --ion-color-dark-tint: #434343;
  /** medium **/
  --ion-color-medium: #646464;
  --ion-color-medium-rgb: 100, 100, 100;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #585858;
  --ion-color-medium-tint: #747474;
  /** lightgray **/
  --ion-color-lightgray: #bebebe;
  --ion-color-lightgray-rgb: 190, 190, 190;
  --ion-color-lightgray-contrast: #000000;
  --ion-color-lightgray-contrast-rgb: 0, 0, 0;
  --ion-color-lightgray-shade: #a7a7a7;
  --ion-color-lightgray-tint: #c5c5c5;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  /** main **/
  --ion-color-main: #3ec9ee;
  --ion-color-main-rgb: 62, 201, 238;
  --ion-color-main-contrast: #000000;
  --ion-color-main-contrast-rgb: 0, 0, 0;
  --ion-color-main-shade: #37b1d1;
  --ion-color-main-tint: #51cef0;
  /** positive **/
  --ion-color-positive: #3dcaff;
  --ion-color-positive-rgb: 61, 202, 255;
  --ion-color-positive-contrast: #000000;
  --ion-color-positive-contrast-rgb: 0, 0, 0;
  --ion-color-positive-shade: #36b2e0;
  --ion-color-positive-tint: #50cfff;
  /** negative **/
  --ion-color-negative: #ff3d3d;
  --ion-color-negative-rgb: 255, 61, 61;
  --ion-color-negative-contrast: #ffffff;
  --ion-color-negative-contrast-rgb: 255, 255, 255;
  --ion-color-negative-shade: #e03636;
  --ion-color-negative-tint: #ff5050;
  /** tabshadow **/
  --ion-color-tabshadow: #d7d6d6;
  --ion-color-tabshadow-rgb: 215, 214, 214;
  --ion-color-tabshadow-contrast: #000000;
  --ion-color-tabshadow-contrast-rgb: 0, 0, 0;
  --ion-color-tabshadow-shade: #bdbcbc;
  --ion-color-tabshadow-tint: #dbdada;
}

.ion-color-lightgray {
  --ion-color-base: var(--ion-color-lightgray);
  --ion-color-base-rgb: var(--ion-color-lightgray-rgb);
  --ion-color-contrast: var(--ion-color-lightgray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgray-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgray-shade);
  --ion-color-tint: var(--ion-color-lightgray-tint);
}

.ion-color-main {
  --ion-color-base: var(--ion-color-main);
  --ion-color-base-rgb: var(--ion-color-main-rgb);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

.ion-color-positive {
  --ion-color-base: var(--ion-color-positive);
  --ion-color-base-rgb: var(--ion-color-positive-rgb);
  --ion-color-contrast: var(--ion-color-positive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-positive-contrast-rgb);
  --ion-color-shade: var(--ion-color-positive-shade);
  --ion-color-tint: var(--ion-color-positive-tint);
}

.ion-color-negative {
  --ion-color-base: var(--ion-color-negative);
  --ion-color-base-rgb: var(--ion-color-negative-rgb);
  --ion-color-contrast: var(--ion-color-negative-contrast);
  --ion-color-contrast-rgb: var(--ion-color-negative-contrast-rgb);
  --ion-color-shade: var(--ion-color-negative-shade);
  --ion-color-tint: var(--ion-color-negative-tint);
}

.ion-color-tabshadow {
  --ion-color-base: var(--ion-color-tabshadow);
  --ion-color-base-rgb: var(--ion-color-tabshadow-rgb);
  --ion-color-contrast: var(--ion-color-tabshadow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tabshadow-contrast-rgb);
  --ion-color-shade: var(--ion-color-tabshadow-shade);
  --ion-color-tint: var(--ion-color-tabshadow-tint);
}

.title-text {
  display: block;
  padding-right: 0.25rem;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
}