/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Mobiscroll */
@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";

/* Font */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

//너비 500px 이상
@media (min-width: 500px) {
  ion-header {
    border-right: 2px solid rgb(240, 240, 240);
    border-left: 2px solid rgb(240, 240, 240);
  }
  ion-content {
    border-right: 2px solid rgb(240, 240, 240);
    border-left: 2px solid rgb(240, 240, 240);
  }
}

ion-toolbar {
  ion-title {
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 90px 1px;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.hide {
  display: none;
}
